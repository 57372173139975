import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import MenuMask from './Components/MenuMask';
import reportWebVitals from './reportWebVitals';

import { PostProvider } from './context/PostContext';

const elem = document.querySelector('#nav-jump-root');
const mElem = document.querySelector('#nav-jump-root-mobile');

const roots = [ReactDOM.createRoot(elem)];
if (mElem) roots.push(ReactDOM.createRoot(mElem));

roots.forEach((root,r) => {
  root.render(
    <React.StrictMode>
      <PostProvider value={{
        type: elem.dataset.postType,
        id: elem.dataset.postId,
        isFront: elem.dataset.isFront === 'true'
      }}>
        <App />
      </PostProvider>
      {/*{r === 0 && (
        <MenuMask nav={elem.parentNode}></MenuMask>
      )}*/}
    </React.StrictMode>
  );
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
