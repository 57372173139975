import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

export const NavItem = props => {

  const isActive = (props.anchor && props.active) ? (props.anchor.name == props.active.name || (props.active.isChild && props.anchor.isTop)) : false;
  const [isContrast,setIsContrast] = useState(false);
  const navLink = useRef(null);

  const maybeSetIsContrast = () => {
    const box = navLink.current.getBoundingClientRect();
    if (box.height && box.width) {
      const stack = Array.from(document.elementsFromPoint(box.left + box.width / 2, box.top + box.height / 2));
      const contrastEl = stack.find(el => {
        if (el.tagName === 'IMG' || el.tagName === 'VIDEO') return true;
        const style = window.getComputedStyle(el);
        return (style.backgroundColor !== 'rgba(0, 0, 0, 0)' && style.backgroundColor !== 'rgb(255, 255, 255)') || style.backgroundImage !== 'none';
      })
      setIsContrast(contrastEl ? true : false);
    }
  }

  useEffect(() => {
    maybeSetIsContrast();
    document.addEventListener("DOMContentLoaded", maybeSetIsContrast);
    window.addEventListener("scroll", maybeSetIsContrast);
  }, []);

  if (props.anchor) {
    return (
      <li className={`nav-item nav__menu-item menu-item menu-item-${props.anchor.name} ${isActive ? 'active' : 'not-active'}`}>
        <a ref={navLink} className={`nav-link nav__menu-link ${isActive ? 'active' : 'not-active'} ${isContrast ? 'contrast' : ''}`} href={props.href}>{props.label}</a>
        {props.children && props.children.length ? (
          <ol className="navbar-nav nav-mobile ml-auto nav__menu nav-children">
          {props.children.map((anchor,a) => {
            return (
              <NavItem href={anchor.dataset.href} label={anchor.dataset.label} active={props.active} anchor={anchor}></NavItem>
            )
          })} 
          </ol>
        ) : ''}
      </li>
    )
  }
  return;
}