import './App.css';
import { FullMenu } from './Components/FullMenu';

function App() {
  return (
    <FullMenu />
  );
}

export default App;
