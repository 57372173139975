import { useState, useEffect, useContext } from "react";
import { NavItem } from "./NavItem";
import PostContext from '../context/PostContext';

const parseNavStructure = () => {
  let anchors = Array.from(document.body.querySelectorAll('.jump-nav-anchor'));
  anchors.forEach(anchor => {
    anchor.dataset.href = anchor.name;
    anchor.isChild = anchor.dataset.child === 'true' ? true : false;
    if (anchor.name[0] === '#') anchor.setAttribute('name',anchor.name.substring(1));
    anchor.isTop = anchor.name === 'top' ? true : false;
  });
  return {
    all: anchors,
    top: anchors.filter(anchor => !anchor.isChild),
    children: anchors.filter(anchor => anchor.isChild)
  };
}

const anchors = parseNavStructure();
const topAnchor = anchors.top.find(anchor => anchor.isTop);
const apiBase = process.env.NODE_ENV === 'development' ? "https://edf-casestudies.localhost.com/wp-json/wp/v2" : "/wp-json/wp/v2";

export const FullMenu = props => {
  const [activeAnchor, setActiveAnchor] = useState(topAnchor);
  const [caseStudies, setCaseStudies] = useState([]);
  const [caseStudyNavAnchors, setCaseStudyNavAnchors] = useState([]);
  //const [navAnchors, setNavAnchors] = useState([]);
  //const [contentNavAnchors, setContentNavAnchors] = useState([]);  

  const post = useContext(PostContext);

  const apiRoutes = {
    caseStudies: `${apiBase}/casestudy?order=asc`,
    current: `${apiBase}/${post.type === 'page' ? 'pages' : post.type}?include[]=${post.id}`
  }

  const handleAnchorScroll = () => {
    if (anchors && anchors.all && anchors.all.length) {
      const body = document.body;
      let a = anchors.all.length - 1;
      let nextAnchor; 
      do {    
        nextAnchor = anchors.all[a];
        a--;
      } while (a >= 0 && (!nextAnchor || nextAnchor.getBoundingClientRect().y > window.innerHeight/2));
      if (activeAnchor) body.classList.remove(`nav-active-${activeAnchor.name}`);
      setActiveAnchor(nextAnchor);
      body.classList.add(`nav-active-${nextAnchor.name}`);
    }
  }

  useEffect(() => {

    fetch(apiRoutes.caseStudies)
      .then((response) => response.json())
      .then((data) => {
        setCaseStudies(data);
        let anchors = [];
        data.forEach(casestudy => {
          anchors.push({
            dataset: {
              label: casestudy.title.rendered,
              href: casestudy.link
            }
          })
        });
        setCaseStudyNavAnchors(anchors);
      });

    window.addEventListener("scroll", handleAnchorScroll);

    ///Switching from API approach for instant rendering
    // fetch(apiRoutes.current)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     const anchors = parseNavStructure(data[0].content.rendered,post.type);
    //     setNavAnchors(anchors.top);
    //     setContentNavAnchors(anchors.children);
    //   });

  }, []);

  //To handle nav text color change on scroll

  // const getMenuTextColor = (sectionId) => {
  //   const section = document.getElementById(sectionId);
  //   const sectionStyles = window.getComputedStyle(section);
  //   const backgroundColor = sectionStyles.backgroundColor;

  //   return backgroundColor === "white" ? "#03c" : "#3cf";
  // };

  // const menuTextColor = getMenuTextColor(visibleSection);

  return (
    <>
      {!post.isFront && (
        <NavItem href={'/'} label={'Home'} anchor={{name: 'home'}}></NavItem>
      )}
      {anchors.top && anchors.top.map((anchor,a) => {
        let children = [];
        if (anchor.dataset.label === 'Case Studies') children = caseStudyNavAnchors;
        if (anchor.isTop && anchors.children && anchors.children.length) children = anchors.children;
        return (
          <NavItem href={anchor.dataset.href} label={anchor.dataset.label} children={children} anchor={anchor} active={activeAnchor}></NavItem>
        )
      })}    
    </>
  );
};
